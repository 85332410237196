// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCqSMeIaqr9cyWEznR6D_dvYc8GRUQRuns",
  authDomain: "zinozee-ed736.firebaseapp.com",
  databaseURL: "https://zinozee-ed736-default-rtdb.firebaseio.com",
  projectId: "zinozee-ed736",
  storageBucket: "zinozee-ed736.appspot.com",
  messagingSenderId: "997882098787",
  appId: "1:997882098787:web:fc179c567101201f283456"
};

// Initialize Firebase
 export const app = initializeApp(firebaseConfig);
