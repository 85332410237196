import Holding from './pages/holding/holding.jsx'
function App() {
  return (
    <div className="App">
      <Holding />
    </div>
  );
}

export default App;
