import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.css'
export default function Icon(Props) {
    const redirectToSocialMedia = (socialMediaUrl) => {
        window.location.href = socialMediaUrl;
      };
  return (
    <>
    <div className={styles.icon} onClick={() => redirectToSocialMedia(Props.link)}>
        <FontAwesomeIcon icon={Props.icon} />
    </div>
    </>
  )
}
