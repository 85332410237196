import styles from "./styles/index.module.css"
// import Card from "./components/card"
import Icon from "./components/icon"
import Logo from "../../assets/images/logo.png"
import tshirt from '../../assets/images/tShirt.jpg'
import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'
import { useState } from "react"
import validator from 'validator'

import {app} from "../../firebase-config.js"
import {getDatabase, ref, set, push, get} from "firebase/database"


export default function Hooding() {

  // const [days, setDays] = useState(0)
  // const [hours, setHours] = useState(0)
  // const [minutes, setMinutes] = useState(0)
  // const [seconds, setSeconds] = useState(0)

  // const [SubscriberArray, setSubscriberArray] = useState([]);

  // var countDownDate = new Date("Jun 24, 2024 15:37:25").getTime();

  // Update the count down every 1 second
  // var x = setInterval(function() {

  // // Get today's date and time
  // var now = new Date().getTime();

  // // Find the distance between now and the count down date
  // var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  // setDays(days)
  // var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  // setHours(hours)
  // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  // setMinutes(minutes)
  // var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  // setSeconds(seconds)

  // Display the result in the element with id="demo"
  // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
  // + minutes + "m " + seconds + "s ";

  // If the count down is finished, write some text
//   if (distance < 0) {
//     clearInterval(x);
    
//   }
// }, 1000);



// function TextCopyer() {
//   // Get the text field
//   var copyText = document.getElementById("copytextid");

//   // Select the text field
//   copyText.select();
//   copyText.setSelectionRange(0, 99999); // For mobile devices

//    // Copy the text inside the text field
//   navigator.clipboard.writeText(copyText.value);

//   // Alert the copied text
//   alert("Copied the text: " + copyText.value);
// }


// Email Validator------------------------------------------------------------------

const [email, setEmail] = useState("example@gmail.com");
  const [message, setMessage] = useState(" ");
  const [isReady, setIsReady] = useState(false);
  function handleEmail(event) {
    let new_Email = event.target.value;
    setEmail(new_Email);
    if (!validator.isEmail(new_Email)) {
      setMessage("Please, enter a valid email!");
    } else {
      setIsReady(true)
      setMessage("")
    }
  }

  // Handle Subscription--------------------------------------
  async function handleSubscribe(){
    if (isReady){
      
      checkIfTheEmailExist(email);
    //  const res = fetch(
    //   "https://zino-96c22-default-rtdb.firebaseio.com/Subscriber.json",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body:JSON.stringify({Email: email})
    //   }
    //  );
    //  if(res){
    //   alert("Subscribed!");
    //  }else{
    //   alert("Subscribe faild!");
    //  }
    }
    }



    async function checkIfTheEmailExist(email){
      let isEmailExist;
        const db = getDatabase(app);
        const dbref = ref(db, "Subscriber");
        const Subscriber = await get(dbref);
        console.log(email)
        if(Subscriber.exists()){
          let EmailArray = Object.values(Subscriber.val());
          for (let x in EmailArray){
            if(EmailArray[x].Email === email){
              console.log(true)
              EmailArray = null;
              isEmailExist = true;
              alert("Already Registered!")
              break;

            }else{
              isEmailExist = false;
            }
          }
          EmailArray = null;
        }
        if(!isEmailExist){
          const db = getDatabase(app);
          const newDocRef = await push(ref(db, "Subscriber"));
          set(newDocRef, {
            Email: email
          }).then(()=>{
            alert("Registered!");
            isEmailExist = true;
          }).catch((err)=>{
            alert("error:",err.message)
          });
         }
    }


  return (
    <>
        <div className={styles.background}>
        </div>
        <div className={styles.container} id='home'>
        <img className={styles.logo} src={Logo} alt="brand logo"/>
        <h1 className={styles.title}>coming fall 2024</h1>
        </div>
        {/* <div className={styles.timer}>
        <Card value={days} text="days"/>
        <Card value={hours} text="hours"/>
        <Card value={minutes} text="minutes"/>
        <Card value={seconds} text="seconds"/>
        </div> */}

        <div className={styles.tShirtContainer}>
              <img src={tshirt} alt="t-shirt"/>
              <p>Register now for a chance to buy one of these exclusive shirts. Limited to just 1,000 worldwide.</p>
        </div>

        <div className={styles.subscribe}>
          {/* <p>Subscribe for an opportunity to get one of a numbered edition tshirts!</p> */}
          <div style = {{ color: "red" }}> {message} </div>
          <div className={styles.inputAndButton}>
            <input className={styles.EmailBox} onFocus={()=>{setEmail("")}} type = "email" value = {email} onChange = {handleEmail}/>
            <button className={styles.SubscribeButton} onClick={handleSubscribe}>Register</button>
          </div>
        </div>

        <div className={styles.shareContainer}>
        <h1>Contacts</h1>
          <div className={styles.share}>
              <div className={styles.icons}>
              <Icon className={styles.icon} icon={faFacebookF} link={"https://www.facebook.com/people/Zino-Zaccagnini/61552363615585/"}/>
              <Icon className={styles.icon} icon={faInstagram} link={"https://www.instagram.com/zinozaccagnini/"}/>
              {/* <Icon className={styles.icon} icon={faLinkedin} link={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent('www.zinozee.com/')}`}/> */}
              </div>
              {/* <div className={styles.copyLink}>
              <div className={styles.inputAndButton}>
                <input className={styles.EmailBox} id="copytextid" type="text" defaultValue='www.zinozee.com/'/>
                <button className={styles.SubscribeButton} onClick={()=>{TextCopyer()}}>Copy</button>
              </div>
              </div> */}
          </div>
        </div>
    </>
  )
}